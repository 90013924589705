<template>
  <div class="home">
    <div class="container-lg" >
      <DonationCard class="mb-5" />
      <QuickLinks />
      <About />
    </div>
    <LatestNews v-if="isUK"/>
    <LatestProjects v-if="!isUK"/>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    DonationCard: defineAsyncComponent(() => import('./views/quickDonate/QuickDonateHome.vue')),
    QuickLinks: defineAsyncComponent(() => import('./views/QuickLinks.vue')),
    About: defineAsyncComponent(() => import('./views/About.vue')),
    LatestNews: defineAsyncComponent(() => import('./views/latestnews/LatestNews.vue')),
    LatestProjects: defineAsyncComponent(() => import('./views/latestprojects/LatestProjects.vue'))
  },
  name: 'Home',
  computed: {
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    goTo (val) {
      window.scrollTo(0, 0)
      this.$router.push('/' + val)
    }
  }
}
</script>

<style scoped>
  .home {
    margin-top: -131px;
  }

  @media (min-width: 992px) {
    .home {
      margin-top: -109px;
    }
  }
</style>
