<template>
  <div class="row m-0">
    <div class="col-12 p-0">
      <div class="container-lg mb-4">
        <PageTitle :title="$route.name" />
        <div class="row mx-0 justify-content-center page_top">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center">
              <div class="col-11 col-md-8 col-lg-7 col-xl-6 text-center p-0 my-4 page_heading">
                Change a life, every day.
              </div>
            </div>
          </div>
        </div>
        <SetupDailySadaqahUK v-if="isUK" />
        <SetupDailySadaqah v-else />
      </div>
      <img src="@/assets/images/daily.jpg" class="img-fluid w-100" />
      <div class="container-lg" >
        <DailySadaqahInfo />
        <YouTubeSection />
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    YouTubeSection: defineAsyncComponent(() => import('./components/YouTubeSection.vue')),
    DailySadaqahInfo: defineAsyncComponent(() => import('./components/DailySadaqahInfo.vue')),
    SetupDailySadaqah: defineAsyncComponent(() => import('./views/za/SetupDailySadaqah.vue')),
    SetupDailySadaqahUK: defineAsyncComponent(() => import('./views/uk/SetupDailySadaqah.vue'))
  },
  name: 'Daily Sadaqah',
  computed: {
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
